<template>
  <b-container class="site" v-snow="snowing">
    <Header/>
    <Navigation />
    <b-breadcrumb v-if="breadcrumb.length" :class="`mt-3 mb-0 ${breadcrumb.length > 2 ? 'd-flex' : 'd-none'} d-sm-flex flex-column flex-sm-row`" :items="breadcrumb"></b-breadcrumb>
    <div class="page pt-3">
      <router-view/>
    </div>
    <CallOrFindUs/>
    <Footer/>
  </b-container>
</template>

<script>
import Navigation from "@/components/layout/Navigation";
import CallOrFindUs from "@/components/layout/CallOrFindUs";
import Footer from "@/components/layout/Footer";
import Header from "@/components/layout/Header";
export default {
  components: {Header, Footer, CallOrFindUs, Navigation},
  computed: {
    snowing() {
      const today = new Date();
      const start = new Date(today.getFullYear(), 11, 1);
      const end = new Date(today.getFullYear() + (today.getMonth() === 11 ? 1 : -1), 0, 5);
      if (this.isHome && (today >= start && today <= end)) {
        console.log("It's snowing!");
        return {
          density: 30,
          fall_speed: 4,
          size: 16,
          color: "#bfc2da",
          opacity: 0.8,
          show: true
        }
      }
      return { show: false };
    },
    isHome() {
      return this.$route.meta.isHome || false;
    },
    breadcrumb() {
      return this.$route.meta.breadcrumb || [];
    }
  }

}
</script>

<style scoped>
  .site {
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    background-color: #ffffff;
    padding: 30px 30px 0px 30px;
    min-height: 100vh;
  }
  @media (max-width: 767px) {
    .site {
      padding: 15px 15px 0px 15px;
    }
  }
</style>