<template>
  <footer class="footer border-top mt-3 pt-3">
    <b-row cols-md="4" cols-sm="2" cols="1">
      <b-col>
        <b-img src="/images/logowhite.png" fluid />
      </b-col>
      <b-col order-sm="3" order-md="2">
        <p class="mt-3 mt-sm-0">
          Cash Box Credit Union Ltd<br>
          Town Hall<br>
          Market Street<br>
          Denton<br>
          M34 2AP
        </p>
      </b-col>
      <b-col order-sm="4" order-md="3" class="text-left text-sm-right text-md-left">
        <p><span class="d-block">Email</span><a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a>
        <p><span class="d-block">Phone</span><a href="tel:01613423266">0161 342 3266</a></p>
      </b-col>
      <b-col order-sm="2" order-md="4">
        <ul class="list-unstyled text-left text-sm-right links">
          <li><router-link to="/about">About Us</router-link></li>
          <li><router-link to="/complaints">Complaints</router-link></li>
          <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
        </ul>
      </b-col>
    </b-row>
    <b-row class="border-top mt-3 pt-3">
      <b-col cols="4" sm="4" md="2" offset-md="3">
        <a href="https://www.fscs.org.uk/" target="_blank">
          <b-img src="/images/fscs_logo.png" fluid alt="Financial Services Compensation Scheme" />
        </a>
      </b-col>
      <b-col cols="4" sm="4" md="2">
        <b-img src="/images/lwf.png" fluid alt="Living Wage Foundation" />
      </b-col>
      <b-col cols="4" sm="4" md="2">
        <b-img src="/images/creditunion.png" fluid alt="Credit Union Community" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p class="text-center small pt-3">
          Cash Box Credit Union is authorised by The Prudential Regulation Authority and regulated by The Financial Conduct Authority and The Prudential Regulation Authority.<br/> Firm Reference Number 213528 Copyright &copy; 2016
        </p>
      </b-col>
    </b-row>
    <cookie-law></cookie-law>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
export default {
  name: "Footer",
  components: {
    CookieLaw
  }
}
</script>

<style scoped>
.links li {
  margin-bottom: 0.5rem;
}
.links li a:link, .links li a:visited {
  color: #868686;
}
</style>