<template>
  <div class="navigation">
    <b-navbar toggleable="md" class="navbar-default">
      <b-container class="p-0">
        <b-button
            variant="info"
            block
            target="navbar"
            class="d-block d-md-none my-2 text-uppercase text-white more-links"
            v-b-toggle:navbar
        >More Links...</b-button>
        <b-collapse id="navbar" is-nav>
          <b-nav justified class="w-100 nav-items flex-column flex-md-row">
            <b-nav-dropdown
              class="btn btn-blue-100"
              text="Savings"
              ref="savings"
              v-b-hover="(v) => hoverHandler('savings', v)">
              <b-dropdown-item to="/savings">Savings</b-dropdown-item>
              <b-dropdown-item to="/savings/christmas-savings">Christmas Savings</b-dropdown-item>
              <b-dropdown-item to="/savings/prize-saver">Prize Saver</b-dropdown-item>
            </b-nav-dropdown>
            <b-nav-item-dropdown
                class="btn btn-blue-75"
                text="Loans"
                ref="loans"
                v-b-hover="(v) => hoverHandler('loans', v)">
              <b-dropdown-item to="/loans/family-loan">Family Loan</b-dropdown-item>
              <b-dropdown-item to="/loans/family-loan-plus">Family Loan Plus</b-dropdown-item>
              <b-dropdown-item to="/loans/loan-protection-insurance">Loan Protection Insurance</b-dropdown-item>
<!--              <b-dropdown-item to="/loans/soundpound-loan">SoundPound Loan</b-dropdown-item>-->
              <b-dropdown-item to="/loans/">Why choose Cash Box for a loan?</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item to="/contact-us" class="btn btn-blue-50">Contact Us</b-nav-item>
            <b-nav-item to="/app" class="btn btn-blue-25">Download App</b-nav-item>
          </b-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  methods: {
    hoverHandler(element, isHovered) {
      this.$refs[element].visible = isHovered;
    }
  }
}
</script>

<style>
.navigation .navbar {
  padding:0;
}
.navigation .nav-items {
  gap: 0.5rem;
}
.navigation .nav-items .btn .nav-link {
  padding:0;
  color:white;
  text-transform: uppercase;
  font-size:0.9em;
}
.navigation .more-links {
  color:white;
  text-transform: uppercase;
  font-size:0.9em;
  margin-top: 0 !important;
}
.navigation .nav-items .btn.btn-blue-100 {
  --link-color: var(--cb-primary-100)
}
.navigation .nav-items .btn.btn-blue-75 {
  --link-color: var(--cb-primary-50);
}
.navigation .nav-items .btn.btn-blue-50 {
  --link-color: var(--cb-secondary-100);
}
.navigation .nav-items .btn.btn-blue-25 {
  --link-color: var(--cb-secondary-50);
}
.navigation .nav-items .btn {
  background-color: var(--link-color);
  border-color: var(--link-color);
}
</style>