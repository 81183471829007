<template>
  <div class="header">
    <div class="d-flex align-items-start justify-content-between flex-column flex-md-row">
      <div class="logo">
        <router-link to="/">
          <b-img src="/images/logo.png" alt="Cash Box Credit Union" fluid />
        </router-link>
        <p class="my-1 my-sm-3 text-success">
          The credit union for people who live or work in Tameside and Glossop
        </p>
      </div>
      <div class="cash-box-actions text-right mt-3 mt-sm-0 w-auto">
          <b-button
              block
              variant="primary"
              href="https://cashbox.cuaccount.com/login/"
              target="_blank"
              class="mb-2 text-uppercase text-nowrap"
              v-b-tooltip.hover.left
              title="Existing members already registered please log in"
          >Login/Members Area</b-button>
          <b-button
              block
              variant="success"
              to="/join-us"
              class="mb-2 text-uppercase text-nowrap"
              v-b-tooltip.hover.left
              title="Join Us"
          >Join Cash Box</b-button
          >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
.header .cash-box-actions .btn {
  font-size:0.9em;
}
@media (max-width: 767px) {
  .header .cash-box-actions  {
    width:100% !important;
  }
}
</style>